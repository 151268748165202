import "../css/components/homepage.scss";
import { Helmet } from "react-helmet-async";
import { Box, Text, Container, VStack } from "@chakra-ui/react";
import lines2 from "../assets/lines.png";
import potatoCatBig from '../assets/potatocat-big.png';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Potato Cat AI</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Box
        position="fixed"
        width="100%"
        height="100vh"
        overflow="hidden"
        bg="#99d6e9!important"
        borderRadius="xl"
      >
        {/* Background */}
        <div
          style={{
            backgroundImage: `url(${lines2})`,
            zIndex: -1,
          }}
          className="fixed w-full h-full bg-no-repeat bg-cover bg-center opacity-10"
        />

        {/* Content Container */}
        <Container maxW="container.lg" centerContent height="100vh">
          <VStack spacing={8} justify="center" height="100%">
            {/* Logo */}
            <div className="w-48"> {/* Reduced size wrapper */}
              <img
                src={potatoCatBig}
                alt="Potato Cat"
                className="w-full h-auto"
              />
            </div>

            {/* Title */}
            <h1 className="text-white text-center text-4xl archivo-black">
              Potato Cat AI
            </h1>

            {/* Notice Message */}
            <Box
              bg="#fff"
              color="#444"
              p={6}
              borderRadius="xl"
              maxW="600px"
              textAlign="center"
              border="1px solid #fc822f"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            >
              <Text fontSize="lg" lineHeight="tall">
                The app is paws-ed for now, and we're not sure when it will land on its feet again.
                But don't worry—existing users will still qualify for future tokens, even if they're
                part of a different project. Follow the{" "}
                <a
                  href="https://x.com/tulle_ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  Tulle account on X
                </a>{" "}
                for updates. Stay curious!
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </>
  );
}
