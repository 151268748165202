import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Router from './router'
import theme from './theme'

import './css/app.scss'
import './tailwind-output.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const App = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <Router />
          </ChakraProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  )
}

root.render(<App />)
